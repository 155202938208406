import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import { SprkDivider, SprkIcon } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 className="sprk-b-PageTitle sprk-b-TypeDisplayOne sprk-u-mbl sprk-u-Measure">
  Alternative Text
    </h1>
    <p>{`UX Writers, designers and developers can use this `}<strong parentName="p">{`Alternative Text Guide`}</strong>{` as an instruction to writing style, usage, text for copy, documentation, reference information, and copy inside Rocket digital experiences.`}</p>
    <SprkDivider element="span" additionalClasses="sprk-u-mvl" mdxType="SprkDivider" />
    <h3 className="sprk-b-TypeDisplayThree sprk-u-mbm sprk-u-Measure">Main Takeaways</h3>
    <ul>
      <li parentName="ul">{`Adding alternative text ensures users with disabilities can understand non-text content.`}</li>
      <li parentName="ul">{`Describe the image without altering the meaning of the screen or referencing the word “image.”`}</li>
      <li parentName="ul">{`Consider how you would describe that image to someone without them having to visually see the image.`}</li>
      <li parentName="ul">{`Decorative images don't need alternative text.`}</li>
      <li parentName="ul">{`Use a null attribute (or empty string) for non-semantic or decorative images, for example those that are for ambiance or other non-semantic purposes.`}</li>
    </ul>
    <h4>{`Accessibility Benefits`}</h4>
    <ul>
      <li parentName="ul">{`Screen readers read alt-text in place of images, which allows low or no-vision users to understand the meaning of the image.`}</li>
      <li parentName="ul">{`When an image fails to load or if content is blocked, the alt-text will appear in it's place.`}</li>
      <li parentName="ul">{`It gives images a text to display so screen readers can read it without altering the meaning of the screen.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvs" mdxType="SprkDivider" />
    <h3>{`Table Of Contents`}</h3>
    <ul>
  <li>
    <a href="#what-is-alternative-text">What Is Alternative Text</a>
  </li>
  <li>
    <a href="#images-and-graphics">Images and Graphics</a>
  </li>
  <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
    <li>
      <a href="#images">Images</a>
    </li>
    <li>
      <a href="#icons">Icons</a>
    </li>
    <li>
      <a href="#svgs">SVG</a>
    </li>
  </ul>
  <li>
    <a href="#writing-effective-alt-text-copy">
      Writing Effective Alt Text Copy
    </a>
  </li>
  <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
    <li>
      <a href="#guidelines">Guidelines</a>
    </li>
    <li>
      <a href="#examples">Examples</a>
    </li>
  </ul>
  <li>
    <a href="#accessibility">Accessibility</a>
  </li>
    <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
    <li>
      <a href="#resources">Resources</a>
    </li>
  </ul>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`What Is Alternative Text`}</h2>
    <p>{`Alternative text or commonly known as `}<strong parentName="p">{`alt-text`}</strong>{` is a word or phrase that describes an image’s appearance and function.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The main purpose of the `}<a href="https://www.w3.org/TR/WCAG21/#non-text-content">{`alt tag`}</a>{` is to support accessibility, so screen readers and other assistive technology users are able to make full use of the content.`}</p>
    </blockquote>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Syntax</h3>
    <p>{`The text inside the alt tag provides the user with a description of the image.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img src="image_of_puppies.png" alt="Four black Goldendoodle puppies">
`}</code></pre>
    <SprkDivider element="span" additionalClasses="sprk-u-mvm" mdxType="SprkDivider" />
    <h3>{`Images and Graphics`}</h3>
    <p>{`Images and graphics make content visually pleasant and easier to understand.`}</p>
    <blockquote>
      <p parentName="blockquote">{`To ensure they are accessible, they must have alternative text that describes the information or function represented by them. `}</p>
    </blockquote>
    <SprkDivider element="span" additionalClasses="sprk-u-mvs" mdxType="SprkDivider" />
    <h3>{`Images`}</h3>
    <p>{`Not all images on the web need the same alt-text. It’s important to consider the context and purpose of the image when writing the text description. `}</p>
    <p>{`Images can be active, informative or decorative.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Active Images`}</strong>{` help communicate meaning, actions, status or feedback.`}</li>
      <li parentName="ul"><strong parentName="li">{`Informative Images`}</strong>{` help users understand the content on a screen. These include: diagrams, charts, graphs, maps or informative icons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Decorative Images`}</strong>{` have no semantic meaning and should have their alt attributes set to the empty (or null) string ("").`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`For more information refer to `}<a href="https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/alt">{`Developer Mozilla's HTML Image Element - alt.`}</a></p>
    </blockquote>
    <SprkDivider element="span" additionalClasses="sprk-u-mvl" mdxType="SprkDivider" />
    <h4>{`Guidelines`}</h4>
    <div className="sprk-b-TableContainer">
  <table className="sprk-b-Table--secondary sprk-b-Table--spacing-medium sprk-b-Table--bordered-rows">
    <thead>
      <tr>
        <th>If The Image Is ...</th>
        <th>How To Identify It?</th>
        <th>The Alt Text Should ...</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Active</td>
        <td>These images perform an action or have functionality. For example, clickable icons are active images.</td>
        <td>Help the user understand what happens when they click the image.</td>
      </tr>
      <tr>
        <td>Informative</td>
        <td>These images contain information the user may need. If the image was removed from the screen, the information would be lost.</td>
        <td>Explain or describe the information found in the image.</td>
      </tr>
      <tr>
        <td>Decorative</td>
        <td>These images are included only for placement or visual effects. An image is decorative if you could remove it from the screen without losing information.</td>
        <td>Blank or null ("").</td>
      </tr>
    </tbody>
  </table>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvm" mdxType="SprkDivider" />
    <h3 className="sprk-b-TypeDisplayThree sprk-u-mbl sprk-u-Measure">Icons</h3>
    <p className="sprk-b-TypeBodyTwo sprk-u-mbl sprk-u-Measure">Icons can potentially convey meaning without using words, although researching users' understanding of icons is critical to ensuring that meaning is clear.</p>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbs sprk-u-Measure">Guidelines</h3>
    <div className="sprk-b-TableContainer">
  <table className="sprk-b-Table--secondary sprk-b-Table--spacing-medium sprk-b-Table--bordered-rows">
    <thead>
      <tr>
        <th>What Is The Purpose Of The Icon?</th>
        <th>How To Identify It?</th>
        <th>The Alt Text Should ...</th>
        <th>Best Practices</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Act as an image</td>
        <td>The icon appears as an image or inline image.</td>
        <td>Help the user understand what the icon image conveys.</td>
        <td>It <strong>must</strong> have an alt attribute.</td>
      </tr>
      <tr>
        <td>For <strong>functionality</strong></td>
        <td>For example, a magnifying glass icon prompts users to activate the search function.</td>
        <td>Provide contextual text accompanying the icon or add a visually hidden string for screen readers. (No alternative text needed.)</td>
        <td>Set the alt attribute to empty or null ("").</td>
      </tr>
      <tr>
        <td>For <strong>decoration</strong></td>
        <td>The icon is purely for visual decor.</td>
        <td>No alternative text needed.</td>
        <td>Set the alt attribute to empty or null ("").</td>
      </tr>
    </tbody>
  </table>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvl" mdxType="SprkDivider" />
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbs sprk-u-Measure">Examples</h3>
    <div className="sprk-b-TableContainer">
  <table className="sprk-b-Table--secondary sprk-b-Table--spacing-medium sprk-b-Table--bordered-rows">
    <thead>
      <tr>
        <th>Icon Image</th>
        <th>What Does The Icon Convey?</th>
        <th>The Alt Text Should ...</th>
        <th>Example</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><SprkIcon iconName="question" additionalClasses="sprk-c-Icon--xl" mdxType="SprkIcon" /></td>
        <td>An image of a question mark icon.</td>
        <td>Give users context about what the icon is trying to communicate.</td>
        <td>alt="Get more information."</td>
      </tr>
      <tr>
        <td><SprkIcon iconName="search" additionalClasses="sprk-c-Icon--xl" mdxType="SprkIcon" /></td>
        <td>An image of a magnifying glass icon.</td>
        <td>Give users instructions as a guide when interacting with the search icon.</td>
        <td>"Use the magnifying icon to start your search."</td>
      </tr>
    </tbody>
  </table>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvl" mdxType="SprkDivider" />
    <h3 className="sprk-b-TypeDisplayThree sprk-u-mbl sprk-u-Measure">SVG</h3>
    <p>{`Scalable Vector Graphics or SVG is an XML-based vector graphics format that specifies the contents of an image as a set of drawing commands that create shapes, lines, apply colors, filters, and so forth.  `}</p>
    <blockquote>
      <p parentName="blockquote">{`SVG files are text files containing source code that, when interpreted, draws the desired image. `}</p>
    </blockquote>
    <p>{`Here are some `}<strong parentName="p">{`benefits`}</strong>{` of using SVG: `}</p>
    <ul>
      <li parentName="ul">{`They are easy to style and animate.  `}</li>
      <li parentName="ul">{`They are great for responsive design because they decrease the screen load time. `}</li>
    </ul>
    <p>{`For more information, visit `}<a href="https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types#svg">{` Developer Mozilla SVG documentation`}</a>{`. `}</p>
    <SprkDivider element="span" additionalClasses="sprk-u-mvl" mdxType="SprkDivider" />
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Usage</h3>
    <p>{`SVG files are ideal for: `}</p>
    <ul>
      <li parentName="ul">{`Diagrams `}</li>
      <li parentName="ul">{`Icons `}</li>
      <li parentName="ul">{`Images that can be accurately drawn at any size, like for user interface elements.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvl" mdxType="SprkDivider" />
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbs sprk-u-Measure">Guidelines</h3>
    <div className="sprk-b-TableContainer">
  <table className="sprk-b-Table--secondary sprk-b-Table--spacing-medium sprk-b-Table--bordered-rows">
    <thead>
      <tr>
        <th>If The SVG Is ...</th>
        <th>What Does The SVG Convey?</th>
        <th>The Alt Text Should ...</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Acting as an image</td>
        <td>It must have alt-text using the alt attribute.</td>
        <td>Give users context about what the SVG image is conveying.</td>
      </tr>
      <tr>
        <td>Intended as an image source</td>
        <td>Communicate to users what the image is conveying.</td>
        <td>If the image is an icon, give users a description. For example, alt="Lightbulb icon."</td>
      </tr>
      <tr>
        <td>Acting as the SVG source</td>
        <td>Must have a title.</td>
        <td>Help users understand what the image is trying to communicate.</td>
      </tr>
      <tr>
        <td>An inline SVG</td>
        <td>Add the alt-text using the (title=" ") element within the SVG image.</td>
        <td>Give users a brief description or advisory information of the graphic.</td>
      </tr>
    </tbody>
  </table>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvl" mdxType="SprkDivider" />
    <h2 className="sprk-b-TypeDisplayTwo sprk-u-mbl sprk-u-Measure">Writing Effective Alt Text Copy</h2>
    <blockquote>
      <p parentName="blockquote">{`As a UX Writer, determine what the alt-text should be depending on the usage, context, and content of the image.`}</p>
    </blockquote>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Guidelines</h3>
    <ul>
      <li parentName="ul"><strong>Determine what information or what the function of the image is.</strong> Copy should describe an image’s purpose or what information it communicates. </li>
      <li parentName="ul"><strong>Choose the appropriate alternative text for the image type. </strong> If the image has no informative value, treat them as decorative images. </li>
      <li parentName="ul"><strong>Describe the image as specifically as possible. </strong> Include the most valuable information at the beginning and provide meaningful descriptions. </li>
      <li parentName="ul"><strong>Keep it as short as possible. </strong> Be concise and avoid keyword stuffing.</li>
      <li parentName="ul"><strong>Use punctuation.</strong>
        <ul className="sprk-b-List sprk-b-List--indented" data-id="unordered-list-2">
    <li>Use sentence case.</li>
    <li>Add space characters between the image and adjacent text to avoid having words running together when a screen reader reads them.</li>
    <li>Include a period at the end of the alt-text – even if it's not a complete sentence. The period will provide a slight pause when read by the screen reader.</li>
    <li>If using a null (empty) alternative text (alt="") to hide decorative images, make sure that there is no space character between the quotes.</li>
        </ul></li>
    </ul>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Examples</h3>
    <p className="sprk-b-TypeBodyTwo sprk-u-mbn sprk-u-Measure">Use these examples as a guideline to create the best alt-text copy.
The examples in the table below, describe the image of a rooster.</p>
    <div className="sprk-b-TableContainer">
  <table className="sprk-b-Table--secondary sprk-b-Table--spacing-medium sprk-b-Table--bordered-rows">
    <thead>
      <tr>
        <th>Average Example</th>
        <th>Better Example</th>
        <th>Best Example</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>alt=“Rooster.”</td>
        <td>alt=“Rooster crowing.“</td>
        <td>alt="Red-crested rooster crowing."</td>
      </tr>
    </tbody>
  </table>
    </div>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Accessibility`}</h2>
    <p>{`Ensure all non-text content (images and graphics) has alternative text so blind users can understand a screen’s content when read by a screen reader.`}</p>
    <p className="sprk-b-TypeBodyTwo sprk-u-mbl sprk-u-Measure"> The content above is not all-inclusive, please review <a href="https://sparkdesignsystem.com/principles/accessibility-guidelines">Spark’s Accessibility Guidelines.</a></p>
    <blockquote>
      <p parentName="blockquote">{`Alternative text enables blind users to interpret images. Refer to `}<a href="https://www.w3.org/TR/WCAG21/#text-alternatives">{` W3’s Text Alternative Guidelines.`}</a></p>
    </blockquote>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbl sprk-u-Measure">Resources</h3>
    <ul>
      <li parentName="ul"><a href="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html">Alternative text for non-text content</a>- WCAG (Web Accessibility Guidelines)</li>
      <li parentName="ul"><a href="https://www.w3.org/TR/WCAG20-TECHS/G82.html">Providing a text alternative that identifies the purpose of the non-text content.</a></li>
      <li parentName="ul"><a href="https://webaim.org/techniques/alttext/">Techniques for writing alternative text - webaim.org.</a> </li>
      <li parentName="ul"><a href="https://www.w3.org/WAI/tutorials/images/decision-tree/">Decision tree for alternative text - WAI(Web Accessibility Initiative)</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      